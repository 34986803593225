<template>
  <div class="list-table">
    <div class="upper-frame" v-if="showUpperFrame">
      <div class="number">
        {{ listTableBodyData.length }}件
        <span class="cancel" v-if="cancelNum >= 1">
          （ キャンセル {{ cancelNum }}件）
        </span>
      </div>
      <div v-if="selectBoxFlg" class="select-boxes">
        <span class="label">並び替え</span>
        <base-select-box
          class="select-box sort"
          :styles="sortBoxStyle"
          :selectData="sortTypes"
          :disabled="sortModeFlg"
          v-model="sortType"
        />
        <base-select-box
          class="select-box order"
          :styles="orderBoxStyle"
          :selectData="orderTypes"
          :disabled="sortModeFlg"
          v-model="orderType"
        />
      </div>
      <div v-if="addButtonText" class="add-button">
        <base-button-plus
          :styles="addButtonStyle"
          :text="addButtonText"
          :disabled="addButtonDisabled"
          @click="$emit('add')"
        />
      </div>
    </div>
    <div class="table-header">
      <list-table-header-row :rowItems="listTableHeaderData" />
    </div>
    <div v-if="listTableBodyData.length > 0" class="table-body" ref="tableBody">
      <div class="table-body-inner">
        <draggable
          class="draggable"
          v-if="sortModeFlg"
          v-model="listTableBodyData"
          @touchstart.native="startTimer"
          @touchmove.native="onMove"
          @touchend.native="stopTimer"
          ><list-table-body-row
            class="sort"
            v-for="listTableRowData in listTableBodyData"
            :key="listTableRowData.id"
            v-bind="listTableRowData"
            @click.native="selectRow(listTableRowData.id)"
          />
        </draggable>
        <div class="draggable" v-else>
          <list-table-body-row
            v-for="listTableRowData in listTableBodyData"
            :key="listTableRowData.id"
            v-bind="listTableRowData"
            :showPrintButton="showPrintButton"
            @print="print"
            @click.native="selectRow(listTableRowData.id)"
          />
        </div>
      </div>
    </div>
    <div v-else>対象のデータはありませんでした</div>
  </div>
</template>

<script>
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import ListTableHeaderRow from '@/components/parts/molecules/ListTableHeaderRow.vue'
import ListTableBodyRow from '@/components/parts/molecules/ListTableBodyRow.vue'
import BaseButtonPlus from '@/components/parts/atoms/BaseButtonPlus'
import Draggable from 'vuedraggable'
import _ from 'lodash'

export default {
  name: 'ListTable',

  components: {
    BaseSelectBox,
    ListTableHeaderRow,
    ListTableBodyRow,
    BaseButtonPlus,
    Draggable
  },

  props: {
    showUpperFrame: { type: Boolean, default: true },
    selectBoxFlg: { type: Boolean, default: false },
    sortBoxStyle: { type: Object, default: () => ({ maxWidth: '300px' }) },
    orderBoxStyle: { type: Object },
    headerData: { type: Array },
    propertyData: {
      type: Array,
      validator: function(arr) {
        return arr.every(v => typeof v === 'string')
      }
    },
    bodyData: { type: Array },
    headerItemStyleData: { type: Array, default: () => [] },
    bodyItemStyleData: { type: Array, default: () => [] },
    sortModeFlg: { type: Boolean, default: false },
    directOrderFlg: { type: Boolean, default: false },
    cancelNum: { type: Number, default: 0 },
    addButtonText: { type: String, default: '' },
    addButtonStyle: {
      type: Object,
      default: () => ({ width: '130px' }),
      validator: obj => Object.values(obj).every(v => typeof v === 'string')
    },
    addButtonDisabled: { type: Boolean, default: false },
    showPrintButton: { type: Boolean, default: false }
  },

  data() {
    return {
      sortType: 0,
      orderType: 0,
      orderTypes: [
        { id: 0, name: '昇順' },
        { id: 1, name: '降順' }
      ],
      touchTime: 0,
      touchTimer: null,
      tableBodyPosition: { top: 0, right: 0, bottom: 0, left: 0 },
      touchPoint: { x: 0, y: 0 }
    }
  },

  computed: {
    sortTypes() {
      return this.headerData.map((v, i) => {
        return { id: i, name: v }
      })
    },
    listTableHeaderData() {
      return this.headerData.map((v, i) => {
        return {
          itemId: i + 1,
          itemText: v,
          itemStyle: this.headerItemStyleData[i]
        }
      })
    },
    listTableBodyData: {
      get() {
        const sortedData = this.bodyData.slice().sort((a, b) => {
          const keyBySortType = this.propertyData[this.sortType]
          if (a[keyBySortType] !== b[keyBySortType]) {
            return a[keyBySortType] < b[keyBySortType] ? -1 : 1
          } else return a.id - b.id
        })
        const orderedData =
          this.orderType === 0 ? sortedData : sortedData.slice().reverse()
        const convertedData = orderedData.map((obj, i1) => {
          return {
            id: obj.id,
            printButtonDisabled: obj.printButtonDisabled ?? false,
            rowItems: this.propertyData.map((key, i2) => {
              return {
                itemId: i2 + 1,
                itemKey: key,
                itemText: this.convertText(obj, key),
                itemStyle: obj.itemStyles
                  ? obj.itemStyles[i2]
                  : this.bodyItemStyleData[i2]
              }
            })
          }
        })
        return this.sortModeFlg || this.directOrderFlg
          ? this.bodyData.map(v1 => {
              return convertedData.find(v2 => v1.id === v2.id)
            })
          : convertedData
      },
      set(data) {
        this.$emit('order', data)
      }
    },
    diffTouchTop() {
      return this.touchPoint.y - this.tableBodyPosition.top
    },
    diffTouchBottom() {
      return this.tableBodyPosition.bottom - this.touchPoint.y
    },
    diffTouchLeft() {
      return this.touchPoint.x - this.tableBodyPosition.left
    },
    diffTouchRight() {
      return this.tableBodyPosition.right - this.touchPoint.x
    },
    scrollUpFlg() {
      return this.diffTouchTop <= 30 &&
        this.diffTouchLeft >= 0 &&
        this.diffTouchRight >= 0
        ? true
        : false
    },
    scrollDownFlg() {
      return this.diffTouchBottom <= 30 &&
        this.diffTouchLeft >= 0 &&
        this.diffTouchRight >= 0
        ? true
        : false
    }
  },

  watch: {
    sortModeFlg: function() {
      if (this.sortModeFlg === true) {
        this.sortType = 0
        this.orderType = 0
      }
    },
    touchTime: function() {
      if (this.scrollUpFlg) {
        this.scrollUp()
      } else if (this.scrollDownFlg) {
        this.scrollDown()
      } else {
        return
      }
    }
  },

  beforeDestroy() {
    clearInterval(this.touchTimer)
  },
  methods: {
    convertText(obj, key) {
      const masterDataNames = Object.keys(this.$store.state.master)
      const dataName = `${key.replace('Id', '')}s`
      const getter = _.camelCase(`get ${dataName}`)
      return masterDataNames.includes(dataName)
        ? this.$store.getters[`master/${getter}`].find(v => v.id === obj[key])
            .name
        : obj[key]
    },
    selectRow(id) {
      if (!this.sortModeFlg) {
        this.$emit('click', id)
      }
    },
    setElementDimensions() {
      this.tableBodyPosition = {
        top: this.$refs.tableBody.getBoundingClientRect().top,
        bottom: this.$refs.tableBody.getBoundingClientRect().bottom,
        left: this.$refs.tableBody.getBoundingClientRect().left,
        right: this.$refs.tableBody.getBoundingClientRect().right
      }
    },
    onMove(e) {
      this.setElementDimensions()
      this.touchPoint = {
        x: e.touches[0].clientX,
        y: e.touches[0].clientY
      }
    },
    startTimer() {
      this.touchTimer = setInterval(() => {
        this.touchTime++
      }, 10)
    },
    stopTimer() {
      clearInterval(this.touchTimer)
      this.touchTime = 0
    },
    scrollDown() {
      this.$refs.tableBody.scrollBy(0, 5)
    },
    scrollUp() {
      this.$refs.tableBody.scrollBy(0, -5)
    },
    print(id) {
      return this.$emit('print', id)
    }
  }
}
</script>

<style lang="scss" scoped>
.list-table {
  > .upper-frame {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    border: solid #{$light-grey};
    border-width: 1px 1px 0px 1px;
    > .number {
      margin-left: 20px;
      font-size: 15px;
    }
    > .select-boxes {
      display: flex;
      align-items: center;
      margin-right: 20px;
      > .label {
        font-size: 13px;
      }
      > .select-box {
        margin-left: 20px;
      }
    }
    > .add-button {
      margin-right: 20px;
    }
  }
  > .table-header {
    box-sizing: border-box;
    height: 40px;
    border: 1px solid #{$light-grey};
  }
  > .table-body {
    box-sizing: border-box;
    max-height: calc(100% - 104px);
    overflow-y: scroll;
    overflow-x: hidden;
    border: solid #{$light-grey};
    border-width: 0px 1px 1px 1px;
    > .table-body-inner {
      > .draggable {
        > .sort {
          cursor: move;
        }
      }
    }
  }
}
</style>
